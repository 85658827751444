import { useContentRoute } from "../composables/pages/useContentRoute.js";
import {usePageMetadata} from "../composables/useMeta.js";

const localeStore = useLocaleStore();

export default defineNuxtRouteMiddleware(async (to, from) => {
    if (to.params.code && from.params.code && localeStore.isLanguageSwitchNavigation) {
        localeStore.clearLanguageSwitch();
        return;
    }

    const pageName = to.params.code ? 'glossaryItem' : 'glossaryPage';

    const page = useState(pageName, () => ({}));
    const pageError = useState('error', () => '');

    const { data, error, fetchPageData } = useContentRoute('glossary');

    await fetchPageData(to);

    page.value = { ...data.value.page };
    pageError.value = error.value;

    usePageMetadata().initializePageMetadata(data.value);
});
